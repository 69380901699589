:root {
    --blue-500: 13, 41, 253;
    --os-blue: #0d29fd;
    --os-blue-rgb: 13, 41, 253;
    --os-blue-start-opacity: 0.9;
    --os-blue-gradient-opacity: 0.5;
    --os-blue-light-start-opacity: 0.1;
    --os-blue-end-opacity: 0.75;
    --os-secondary: #6c00ff;
    --toastify-toast-min-height: 56px;
    --toastify-text-color-light: #000;
    --toastify-toast-width: 430px;
    --os-red-rgb: 251, 97, 108;
    --os-red-start-opacity: 0.2;
    --os-red-light-start-opacity: 0.1;
    --os-green-rgb: 128, 211, 132;
    --os-green-start-opacity: 0.2;
    --os-purple-rgb: 108, 0, 255;
    --os-purple-start-opacity: 0.1;
    --os-orange-light-rgb: 250, 229, 186;
    --chapters-bg: #fff;
}

.loader {
    border-top-color: #3498db !important;
    animation: spinner 1.5s linear infinite;
}

body {
    @apply bg-gray-100 text-black font-roboto text-base 2xl:text-lg;
    background: linear-gradient(to left, rgba(var(--os-orange-light-rgb), 1) 0%, transparent 50%);
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.vertical-seperator {
    background: linear-gradient(to bottom, var(--blue-500), var(--blue-500) 50%, transparent 50%, transparent 100%);
    @apply bg-[length:1px_8px] w-[1px] bg-top bg-repeat-y opacity-50;
}

.hor-divider {
    background: linear-gradient(to right, var(--os-secondary), var(--os-secondary) 50%, transparent 50%, transparent 100%);
    @apply bg-[length:8px_1px] h-[1px] bg-left bg-repeat-x;
}

/* Webkit Scrolbar */

.scroll-block::-webkit-scrollbar {
    @apply w-[5px];
}

.scroll-block::-webkit-scrollbar-track {
    @apply bg-light-gray;
}

.scroll-block::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
}

/* Utilities */

.tooltip {
    @apply group-hover:translate-x-9 group-hover:opacity-100 duration-300 ease-in-out transition-all opacity-0 absolute left-8 top-1/2 -translate-y-1/2 rounded-md shadow-md text-white bg-blue-500 px-4 translate-x-5 p-2 text-center min-w-max font-podkova font-medium pointer-events-none before:absolute before:-left-1 before:top-[calc(50%-.25rem)] before:h-2 before:w-2 before:bg-inherit before:rounded-lg;
}

.user-bg {
    background: linear-gradient(321deg, rgba(13, 41, 253, 0.5) 0%, rgba(13, 41, 253, 1) 60%);
}

.seminar-item {
    @apply flex flex-col md:flex-row justify-evenly pl-6 md:pl-8 pr-4 md:pr-6 pt-4 md:pt-6 pb-4 bg-white shadow-4xl rounded-lg relative after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:w-20 after:rounded-l-lg;
}

.seminar-list {
    @apply flex flex-col gap-8 md:gap-12 p-4 md:py-12 md:px-8;
}

.seminar-list .table td {
    @apply p-0;
}

.seminar-list .table-striped tbody tr:nth-of-type(odd) {
    @apply bg-transparent;
}

.seminar-list .table-responsive {
    @apply overflow-visible;
}

.seminar-list table .seminar-item {
    @apply my-7 md:my-6;
}

.seminar-item-left {
    @apply w-2 absolute left-0 top-0 h-full rounded-l-lg overflow-hidden before:content-[''] before:h-full before:absolute before:left-0 before:top-0 before:border-l-[3px] before:border-solid;
}

.seminar-started .seminar-item-left {
    @apply before:border-light-green;
}

.seminar-started {
    @apply bg-gradient-to-b from-os-green-start to-white;
}

.seminar-planned .seminar-item-left {
    @apply before:border-secondary;
}

.seminar-planned {
    @apply after:bg-gradient-to-r from-os-purple-start to-white;
}

.seminar-ended .seminar-item-left,
.item-not-verified .seminar-item-left {
    @apply before:border-blue-500;
}

.seminar-ended,
.item-not-verified {
    @apply after:bg-gradient-to-r from-os-blue-light-start to-white;
}

.seminar-canceled .seminar-item-left {
    @apply before:border-dark-gray;
}

.seminar-canceled {
    @apply after:bg-none;
}

.seminar-canceled .seminar-time,
.seminar-canceled .seminar-viewer,
.seminar-canceled .seminar-title {
    @apply opacity-50;
}

.item-error .seminar-item-left {
    @apply before:border-dark-red;
}

.item-error {
    @apply before:bg-dark-red after:bg-gradient-to-r from-os-red-light-start to-white;
}

.seminar-info {
    @apply absolute -left-2 md:-left-4 -top-6 -translate-y-1/2 flex;
}

.seminar-info-inner {
    @apply rounded-lg shadow-sm-light py-1 md:py-2 px-1 md:px-2 text-sm lg:text-base;
}

.broadcast-header-actions .option-menu {
    @apply static;
}

.dropdown-gray .option-menu {
    @apply relative;
}

.broadcast-header-actions .dropdown-gray .option-menu-content {
    @apply bg-gray-100 right-0 max-w-full left-0 rounded-l-3xl shadow-sm py-0;
}

.broadcast-header-actions .option-menu-content {
    @apply max-w-md right-4 -translate-y-0 shadow-4xl;
}

.question-icon {
    @apply w-8 h-8 inline-flex items-center justify-center border-2 border-solid border-blue-500 rounded-full text-blue-500 text-2xl font-medium;
}

.btn-disabled {
    @apply opacity-40 pointer-events-none;
}

.countdown-timer .countdown-time {
    @apply text-5xl font-light font-roboto tracking-widest;
}

.notallowed-broadcast .notallowed-broadcast-date {
    @apply inline font-normal text-base;
}

.notallowed-broadcast .notallowed-broadcast-date-inner {
    @apply whitespace-normal;
}

.link {
    @apply underline decoration-blue-500 decoration-2 underline-offset-4;
}

/* Styles for minimized/maximized Popup */

.broadcast-countdown-popup-maximized .popup-sm {
    @apply transition-all ease-in-out duration-300 overflow-hidden;
}

.broadcast-countdown-popup-minimised .popup-sm-wrapper {
    @apply bg-transparent pointer-events-none;
}

.broadcast-countdown-popup-minimised .popup-sm {
    @apply mt-2 py-0 px-5 max-w-xs shadow-4xl pointer-events-auto;
}

.broadcast-countdown-popup-maximized .popup-sm {
    @apply mt-16;
}

.broadcast-countdown-popup-minimised .popup-inner {
    @apply pt-1;
}

.broadcast-countdown-popup-minimised .countdown-timer {
    @apply pl-2;
}

.broadcast-countdown-popup-minimised .countdown-timer .countdown-time {
    @apply text-3xl;
}

.broadcast-countdown-popup-minimised .countdown-inner {
    @apply p-0 my-0 border-none;
}

.broadcast-countdown-popup-minimised .countdown-unit {
    @apply hidden;
}

/* Arrow Animation */

.startBC__toggle #arrow1,
.startBC__toggle #arrow2 {
    fill: var(--text-color);
    transition: transform 300ms cubic-bezier(1, 0.18, 0.465, 0.85) 100ms;
}

.startBC__toggle #arrow1 {
    transform: translate(0, 0);
}

.startBC__toggle #arrow2 {
    transform: translate(15px, 2px) scale(0.5);
}

.broadcast-countdown-popup-minimised .startBC__toggle #arrow1 {
    transform: translate(15px, 8px) scale(0.5);
}

.broadcast-countdown-popup-minimised .startBC__toggle #arrow2 {
    transform: translate(8px, -2px);
}

#mobileMenu li.active button::after {
    --tw-scale-x: 1;
}

#mobileMenu li.active button svg line,
#mobileMenu li.active button svg path {
    stroke-dashoffset: 0;
}

.feedback-popup .popup-sm {
    @apply max-w-[90%] lg:max-w-3xl p-0 overflow-hidden;
}

.feedback-popup .popup-inner {
    @apply py-0;
}

.feedback-popup iframe {
    @apply h-[80vh];
}

.logo {
    @apply w-10 md:w-12 lg:w-[72px];
}

.line-clamp-2 {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.seminars-table :where(thead, tfoot) :where(th, td) {
    @apply bg-white;
}

.seminars-table tbody tr:nth-child(odd) td,
.seminars-table tbody tr:nth-child(odd) th {
    @apply bg-gray-50; /* Light background color for odd rows */
}

.seminars-table tbody tr:nth-child(even) td,
.seminars-table tbody tr:nth-child(even) th {
    @apply bg-white; /* Light background color for even rows */
}
